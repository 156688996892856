import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Single from "./pages/single/Single";
import Write from "./pages/write/Write";
import Settings from "./pages/settings/Settings";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
  redirect,
  Outlet
} from "react-router-dom";

const user = false;

const Layout = () => (
  <>
    <Navbar />
    <Outlet />
  </>
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "write",
        element: <Write />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "post/:postId",
        element: <Single />,
      },
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
