import "./singlePost.css"

export default function SinglePost() {
  return  (
    <div className="singlePost">
      <div className="singlePostWrapper">
        <img
          className="singlePostImg"
          src="https://images.pexels.com/photos/6685428/pexels-photo-6685428.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
          alt=""
        />
        <h1 className="singlePostTitle">
          Lorem  ipsum donor sit amet.
          <div className="singlePostEdit">
            <i className="singlePostIcon fa-solid fa-pen-to-square"></i>
            <i className="singlePostIcon fa-solid fa-trash"></i>
          </div>
        </h1>
        <div className="singlePostInfo">
          <span className="singlePostAuthor">Author: <b>Vegeta</b></span>
          <span className="singlePostDate">1 hour ago</span>
        </div>
        <p className="singlePostDesc">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A diam maecenas sed enim ut sem. Quisque id diam vel quam elementum pulvinar. Ut tortor pretium viverra suspendisse potenti nullam ac tortor. Pellentesque nec nam aliquam sem et. Commodo sed egestas egestas fringilla phasellus faucibus scelerisque eleifend. Dui ut ornare lectus sit. Nascetur ridiculus mus mauris vitae. Diam ut venenatis tellus in metus. Aliquam ultrices sagittis orci a scelerisque purus semper eget duis. At varius vel pharetra vel turpis. Quis risus sed vulputate odio ut enim blandit volutpat. Pretium viverra suspendisse potenti nullam ac tortor vitae purus. Varius morbi enim nunc faucibus a pellentesque sit amet porttitor.

        Nibh cras pulvinar mattis nunc sed blandit. Orci nulla pellentesque dignissim enim sit amet venenatis urna cursus. Tortor at risus viverra adipiscing at in. Vel turpis nunc eget lorem dolor sed. Volutpat commodo sed egestas egestas fringilla phasellus faucibus. Faucibus pulvinar elementum integer enim neque volutpat ac. Amet nulla facilisi morbi tempus iaculis urna. Quam nulla porttitor massa id neque aliquam vestibulum. Dignissim sodales ut eu sem integer. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim. Odio aenean sed adipiscing diam donec. Eget sit amet tellus cras adipiscing enim eu. Eu augue ut lectus arcu bibendum at varius. Integer enim neque volutpat ac tincidunt vitae. Sit amet porttitor eget dolor morbi non. Mi bibendum neque egestas congue quisque.

        Dui id ornare arcu odio ut sem nulla pharetra diam. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Mattis rhoncus urna neque viverra justo nec. Turpis massa tincidunt dui ut ornare lectus sit. Sed elementum tempus egestas sed sed risus pretium quam vulputate. Fermentum dui faucibus in ornare quam viverra orci sagittis. Pellentesque habitant morbi tristique senectus. Erat imperdiet sed euismod nisi porta. In mollis nunc sed id semper risus in. Sit amet facilisis magna etiam tempor orci eu lobortis. Sed elementum tempus egestas sed sed risus pretium. Et ligula ullamcorper malesuada proin libero nunc consequat interdum varius. Id ornare arcu odio ut sem nulla pharetra. Aliquam sem fringilla ut morbi tincidunt augue interdum. Arcu dictum varius duis at. Dignissim sodales ut eu sem integer. Faucibus scelerisque eleifend donec pretium vulputate sapien nec. Augue neque gravida in fermentum et sollicitudin ac. At imperdiet dui accumsan sit amet nulla facilisi morbi tempus. Blandit volutpat maecenas volutpat blandit.
        </p>
      </div>
    </div>
  )
}
