import "./login.css"

export default function Login() {
  return (
    <div className="login">
      <span className="loginTitle">
        Login
      </span>
      <form className="loginForm">
        <label>Email</label>
        <input type="text" className="loginInput" placeholder="bruce@wayne.com" />
        <label>Password</label>
        <input type="password" className="loginInput" placeholder="Open Sesame.." />
        <button className="loginButton">Login</button>
      </form>
      <button className="loginRegisterButton">Register</button>
    </div>
  )
}
