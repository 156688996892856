import "./sidebar.css"
import robot1 from "../../assets/Robot1.jpg"

export default function Sidebar() {
  return (
      <div className="sidebar">
        {/*<div className="sidebarItem">
          <span className="sidebarTitle">We are...</span>
          <img src={robot1} alt=""/>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
        </div>*/}
        <div className="sidebarItem">
          <span className="sidebarTitle">CATEGORIES</span>
          <ul className="sidebarList">
            <li className="sidebarListItem">Technology</li>
            <li className="sidebarListItem">Sports</li>
            <li className="sidebarListItem">Trending</li>
            <li className="sidebarListItem">Politics</li>
            <li className="sidebarListItem">Movies</li>
          </ul>
        </div>
        <div className="sidebarItem">
          <span className="sidebarTitle">FOLLOW US</span>
          <div className="sidebarSocial">
            <i className="sidebarIcon fa-brands fa-facebook"></i>
            <i className="sidebarIcon fa-brands fa-twitter"></i>
            <i className="sidebarIcon fa-brands fa-whatsapp"></i>
            <i className="sidebarIcon fa-brands fa-telegram"></i>
          </div>
        </div>
      </div>
  )
}
