import Header from "../../components/header/Header"
import Posts from "../../components/posts/Posts"
import Products from "../../components/products/Products"
import Sidebar from "../../components/sidebar/Sidebar"
import "./home.css"

export default function Home() {
  return (
      <>
        <Header />
        <div className="home">
          <Products />
        </div>
      </>
  )
}
