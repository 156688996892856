import "./register.css"

export default function Register() {
  return (
    <div className="register">
      <span className="registerTitle">
        Register
      </span>
      <form className="registerForm">
        <label>Username</label>
        <input type="text" className="registerInput" placeholder="Bruce Wayne" />
        <label>Email</label>
        <input type="text" className="registerInput" placeholder="bruce@wayne.com" />
        <label>Password</label>
        <input type="password" className="registerInput" placeholder="Open Sesame.." />
        <button className="registerButton">Register</button>
      </form>
      <button className="registerLoginButton">Login</button>
    </div>
  )
}
