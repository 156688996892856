import "./header.css"
import collage from "../../assets/collage.jpg"

export default function Header() {
  return (
      <div className="header">
        <div className="headerTitles">
          <span className="headerTitleSm">where ideas collide</span>
          <span className="headerTitleLg">Frisoft</span>
        </div>
        <img
          className="headerImg"
          src={collage}
          alt=""
        />
      </div>
  )
}
