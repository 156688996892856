import "./products.css"

export default function Products() {
  return (
      <div className="products">
        <div class="container">
          <div class="card">
            <h2>FriBlog</h2>
            <i className="prodIcon fa-solid fa-arrow-right"></i>
            <p> for blogging.</p>
            <div class="pic"></div>
            <button>
            </button>
          </div>
          <div class="card card2">
            <h2>FriTuts</h2>
            <i className="prodIcon fa-solid fa-arrow-right"></i>
            <p>for tutorials.</p>
            <div class="pic"></div>
            <button>
            </button>
          </div>
          <div class="card card3">
            <h2>FriNews</h2>
            <i className="prodIcon fa-solid fa-arrow-right"></i>
            <p>for announcements.</p>
            <div class="pic"></div>
            <button>
            </button>
          </div>
          <div class="card card4">
            <h2>FriTrendz</h2>
            <i className="prodIcon fa-solid fa-arrow-right"></i>
            <p>for latest debates.</p>
            <div class="pic"></div>
            <button>
            </button>
          </div>
        </div>
      </div>
  )
}
